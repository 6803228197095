<template>
  <div class="content">
    <!-- 导航栏 -->
    <el-header class="zg--header--main">
        <img class="zg--header--logo"  src="https://image.qll-times.com/2021/04/e08a661764ea49c6a03d5278aa74283e.png">
        <label class="zg--header--title">商机圈子平台</label>
    </el-header>
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/square/companycard' }">公司名片</el-breadcrumb-item>
        <el-breadcrumb-item>{{$route.query.name || '您'}}的名片</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
      <div class="header-item" style="margin-left:20px">
        <div class="item-content">
          <label>操作：</label>
          <el-select v-model="selectValue" style=" width: 160px"  placeholder="全部" clearable @clear="getList">
             <el-option v-for="item in operationList"
              :key="item.id"
              :label="item.label" 
              :value="item.id">
            </el-option> 
          </el-select>
        </div>
    </div>
    <div class="header-item">
      <div class="item-content">
        <label></label>
        <el-input style="display: inline-block; width: 160px"
           placeholder="请输入姓名"></el-input>
      </div>
    </div>
    <div class="header-item">
      <el-button type="primary"  icon="el-icon-search" @click="getList">搜索</el-button>
    </div>

      <!--table公司名片表格  -->
    <el-table  tooltip-effect="dark" text-align='center' :data="dataList"  align="center"
    class="tableBox" style="width: 100%; margin-top: 10px"
    header-row-class-name="table-header-style"
    :header-cell-style="{'background-color': 'rgba(249, 249, 249)'}">
      <el-table-column label="头像"  width="90" fixed >
          <template slot-scope="scope">
          <img style="width: 40px;height:40px" 
            :src="scope.row.avatar || $configs.headImg"> 
        </template>
      </el-table-column>
      <el-table-column label="姓名" width="160" >
        <template slot-scope="scope">
          {{scope.row.nickname || '李鑫'}}
        </template>
      </el-table-column>
      <el-table-column label="手机号" width="240" >
        <template slot-scope="scope">
          {{scope.row.tel || '138xxxxxxxx'}}
        </template>
      </el-table-column>
      <!-- 字段暂缺 -->
      <el-table-column label="职位" width="240" >
        <template slot-scope="scope">
          {{scope.row.position || '客户经理'}}
        </template>
      </el-table-column>
      <!-- 字段暂缺 -->
      <el-table-column label="公司"   >
          <template slot-scope="scope">
          {{scope.row.address || '北京大龙科技'}}
        </template>
      </el-table-column>
        <el-table-column label="时间"   >
          <template slot-scope="scope">
          {{scope.row.last_visit_time | dateFormat('YYYY-MM-DD HH:mm:ss')}}
        </template>
      </el-table-column>
        <el-table-column label="操作"   width="200">
          <template slot-scope="scope">
          {{scope.row.address || '查看'}}
        </template>
      </el-table-column>
        <el-table-column label="关联"   width="200"  >
          <template slot-scope="scope">
          {{scope.row.address || '游客'}}
        </template>
      </el-table-column>

    </el-table>
    <div class="tolal">
      共{{total}}位
    </div>
  </div>
</template>
<script>
import Header from '../../components/Header'
export default {
  components: {
    'zg-header': Header
  },
  data() {
    return {
      currentTime:'2019-01-25',
      params: {
        size: 1000,
        after: '',
      },
      dataList:[],//名片详情表格数据
      operationList:[
        {id:'',label:'全部'},
        {id:'collect',label:'收藏'},
        {id:'raiseup',label:'点赞'},
        {id:'visit',label:'查看'},
      ],
      selectValue:'',//操作列表select 收藏点赞查看，
      total:0
    }
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      let query = {
        // xid:this.$route.params.xid,
        xid:this.$route.query.xid,
        evt:this.selectValue,
        rt:'card',
        status:1,
      }
      let res = await this.$ZGManager.statCustomerList(query,this.params)
      console.log(res);
      if (res.status == 200) {
        this.dataList = res.data.list;
        this.total = this.dataList.length;
      }
 
    },
  },

}
</script>
<style lang="scss" scoped>
.content{
  padding: 10px;
  padding-bottom: 80px;
  background-color: #fff;
  margin-bottom: 50px;
  font-size: 14px;
   ::v-deep .cell{
    // width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
   .pagination {
    float: right;
    margin-top: 20px;
  }
  .breadcrumb {
    padding: 20px;
    span {
      &:nth-child(1) {
        cursor: pointer;
      }
      &:nth-child(1):hover {
       color: #409EFF;
      }
    }
  }
  .header-item{
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  // padding: 20px;
  vertical-align: middle;
  &:nth-child(1) {
    margin-left: 30px;
  }
 }
  .tolal {
    float: right;
    margin-top: 20px;
    margin-right: 20px;
    height: 40px;
    width: 100px;
    border: 1px solid #409EFF;
    text-align: center;
    line-height: 40px;
  }
}
</style>
